import request, {exportService} from '@/utils/request'
import {objectToQueryString} from "@/utils/utils";
import {MessageBox} from "element-ui";

//获取停车场列表  //motionType  0-运营,1-机关事业单位,2-联网
export function getParkingLot(params) {
    return request.post('/common/parkingLotList?' + objectToQueryString(params));
}

export function checkToken() {
    return request.post('/role/checkToken')
}

// 接口访问间隔>10秒时，校验一次token有效性
export function isTokenTimeOut(){
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    var timeouted = false;
    var tempVar = window.localStorage.getItem('lastApiAccessTime')
    var curTime = Date.now();
    // debugger
    if(tempVar!=null){
        var lastApiAccessTime = parseInt(tempVar)
        window.localStorage.setItem('lastApiAccessTime', curTime.toString())
        console.log('curTime: ', curTime, 'lastApiAccessTime: ', lastApiAccessTime, curTime-lastApiAccessTime)
        if(curTime-lastApiAccessTime>10*1000){
            var res = checkToken()
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
                timeouted = true
                MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                    confirmButtonText: '重新登录',
                    callback: () => {
                        window.localStorage.clear()
                        window.$vue.$router.push('/login')
                    }
                });
            }else{
                window.localStorage.setItem('lastApiAccessTime', curTime.toString())
            }
        }
    }else{
        window.localStorage.setItem('lastApiAccessTime', curTime.toString())
    }
    return timeouted
}

export function doUpload() {
    return null;
}

export function getRegionList(permissionId) {
    return request.post('/logins/getRegionByUser?permissionId=' + permissionId);
}

/**
 * 获取结算方列表
 * @returns
 */
export function getSettlementList(permissionId) {
    return request.get('/settlement/findSettlementInfoByPage?pageSize=9999&permissionId=' + permissionId);
}

//获取事件列表
export function getCouponEventList() {
    return request.get('/couponController/getCouponEventList')
}

//获取承担方列表
export function getMerchantList() {
    return request.get('couponController/getMerchantList')
}

export function getMerchantList2(params) {
    return request.post('/common/merchantList?' + objectToQueryString(params));
}


//根据关键字搜索车牌列表
export function getNumplateListByKeyword(plateNumber) {
    return request.post('/common/searchAutoInfo?plateNumber=' + plateNumber);
}

// 获取附件URL
export function getAttachmentURL(attachmentId) {
    return request.post('/common/getPhotoUrl?id=' + attachmentId);
}

//获取岗亭人员列表
export function getSentryBoxList(parkingLotId) {
    return request.get('/sentryBoxController/getGuardsList?parkingLotId=' + parkingLotId + '&workStatus=0&phoneNum=&isExport=0&exportSize=1&pageNo=1&pageSize=999')
}

/**
 * 上传附件到oss
 * @param {*} params {image: ''}
 * @returns
 */
export function uploadFileToOSS(params) {
    return request.post('oss/uploadImage', params);
}

/**
 * 通用导出方法
 * @param {*} path 请求路径
 * @param {*} params 参数
 * @param {*} fileName 文件名称
 */
export function doExport(path, params, fileName) {
    exportService.get(`${path}?${objectToQueryString(params)}`).then(res => {
        const a = document.createElement('a');
        a.style.display = 'none'
        a.href = URL.createObjectURL(res.data)
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    });
}

//获取长租套餐列表
export function getLeaseList(params) {
    return request.post('/common/leaseList?' + objectToQueryString(params));
}

//结算方列表
export function getSettleList(params) {
    return request.post('/common/settleList?' + objectToQueryString(params));
}

// 充电站列表
export function getCommonChargeList(params) {
    return request.post('/common/getChargeList?' + objectToQueryString(params));
}