<template>
    <div class="home page">
        <!--  -->
        <div style="display: flex;width: 100%;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="城区" prop="regionId">
                    <el-cascader ref="regionId" size="small" v-model="query.regionIds" :clearable="true"
                        :filterable="true" :props="props" :show-all-levels="false" placeholder="请选择城区"
                        @change="changeRegion" />
                </el-form-item>
                <el-form-item label="时间选择" style="display: flex;align-items: center;">
                    <el-select size="small" v-model="query.dateType" placeholder="请选择时间" filterable
                        @change="onChangeParkingLot" style="width: 100% !important;">
                        <el-option label="今日" value="0"></el-option>
                        <el-option label="近七天" value="1"></el-option>
                        <el-option label="近三十天" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="充电类型" style="display: flex;align-items: center;">
                    <el-select size="small" v-model="query.districtType" placeholder="请选择类型" filterable
                        @change="onChangeParkingLot" style="width: 100% !important;">
                        <el-option label="二轮车充电" value="0"></el-option>
                        <el-option label="新能源充电" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="充电站" class="parking-item">
                    <!-- <SuggestParking v-model="query.parkingLotIds" @parkingName="onChangeParkingLot" :multiple="true" style="width: 30vw;" /> -->
                    <el-select size="small" v-model="query.districtIds" :multiple="true" placeholder="请选择充电站" clearable
                        filterable @change="onChangeParkingLot" style="width: 100% !important;">
                        <el-option v-for="(item, index) in parkingList" :key="index" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

            </el-form>
        </div>
        <div class="board">
            <!-- 一.顶部统计数据、车位利用率 -->
            <div class="board_top fx bottom12">
                <!-- 1.顶部统计数据 -->
                <div class="top_data right12 " style="width: 66%!important;">
                    <div class=" flx bottom12">
                        <div class="top_data_block top_data_block1 fx right12">
                            <img class="top_img" src="@/assets/images/newhome/home_1.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">收益</p>
                                <p class="top_main_num">{{ allTotal.chargeIncome }}</p>
                                <!-- <p class="top_main_bottom">昨日收益：{{ allTotal.yesterdayEarnTotal || 0 }}</p> -->
                            </div>
                        </div>
                        <div class="top_data_block top_data_block2 fx right12">
                            <img class="top_img" src="@/assets/images/newhome/home_2.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">充电次数</p>
                                <p class="top_main_num">{{ allTotal.chargeCount || 0 }}</p>
                                <!-- <p class="top_main_bottom">上月收益：{{ allTotal.lastMonthEarnTotal || 0 }}</p> -->
                            </div>
                        </div>
                        <div class="top_data_block top_data_block3 fx">
                            <img class="top_img" src="@/assets/images/newhome/home_3.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">充电电量</p>
                                <p class="top_main_num">{{ allTotal.chargeElec }}</p>
                            </div>
                        </div>
                    </div>

                    <div class=" flx">
                        <div class="top_data_block top_data_block4 fx right12">
                            <img class="top_img" src="@/assets/images/newhome/home_4.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">充电总时长</p>
                                <p class="top_main_num">{{ allTotal.chargeDuration }}</p>
                            </div>
                        </div>
                        <div class="top_data_block top_data_block5 fx right12">
                            <img class="top_img" src="@/assets/images/newhome/home_5.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">{{ query.districtType == 0 ? '插座利用率' :'充电枪利用率' }}</p>
                                <p class="top_main_num">{{ allTotal.chargeUseRate }}</p>
                            </div>
                        </div>
                        <div class="top_data_block top_data_block6 fx">
                            <img class="top_img" src="@/assets/images/newhome/home_6.png" alt="">
                            <div class="top_main">
                                <p class="top_main_title">设备故障数</p>
                                <p class="top_main_num">{{ allTotal.chargeDeviceFault || 0 }}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- 2.车位利用率 -->
                <div class="top_use board_box">
                    <!-- <div class="headTitle">
              充电桩利用率
            </div> -->
                    <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
                        <!--                <img src="@/assets/images/home/group.png" />-->
                        <div>
                            <span style="margin-left: 0;">充电桩利用率</span>
                        </div>
                        <div style="display: flex;" v-if="query.districtType == 1">
                            <div class="margin-left20">
                                <el-radio-group v-model="query.type2" size="small" @change="chargeDeviceUseRate">
                                    <el-radio-button label="1">快充</el-radio-button>
                                    <el-radio-button label="0">慢充</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="chart-content">
                        <BaseChart ref="zrcwlylChart" :option="zrcwlylChartOption" style="width: 100%; height: 100%" />
                    </div>
                </div>
            </div>
            <!-- 二.收入趋势、泊位占用 -->
            <div class="board_second fx bottom12">
                <!-- 1.收入趋势 -->
                <div class="board_income board_box" style="width: 66%!important;">
                    <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
                        <!--                <img src="@/assets/images/home/group.png" />-->
                        <div>
                            <span style="margin-left: 0;">收入趋势</span>
                        </div>
                        <div style="display: flex;" v-if="query.districtType == 1">
                            <div class="margin-left20">
                                <el-radio-group v-model="query.type3" size="small" @change="incomeAnalyze">
                                    <el-radio-button label="0">全部</el-radio-button>
                                    <el-radio-button label="2">快充</el-radio-button>
                                    <el-radio-button label="1">慢充</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="chart-content-income">
                        <BaseChart ref="topChart" :option="topChartOption" style="width: 100%; height: 100%" />
                    </div>
                </div>
                <!-- 2.综合分析 -->
                <div class="board_occupied board_box">
                    <div class="headTitle">
                        充电综合分析
                    </div>
                    <div class="board_occupied_btm flx">
                        <div class="board_occupied_main fx">
                            <div class="board_occupied_box">
                                <p class="board_occupied_title">充电桩总数</p>
                                <p class="board_occupied_num">{{ occupiedData.chargeDeviceCount || 0 }}</p>
                            </div>
                            <div class="board_occupied_box">
                                <p class="board_occupied_title">充电插座总数</p>
                                <p class="board_occupied_num">{{ occupiedData.chargeSubDeviceCount || 0 }}</p>
                            </div>
                            <div class="board_occupied_box">
                                <p class="board_occupied_title">当前充电中</p>
                                <p class="board_occupied_num" style="color: #08BF9E;">{{ occupiedData.chargeSubDeviceUse
                                    || 0 }}</p>
                            </div>
                            <div class="board_occupied_box">
                                <p class="board_occupied_title">当前空闲</p>
                                <p class="board_occupied_num">{{ occupiedData.chargeSubDeviceFree || 0 }}</p>
                            </div>
                            <div class="board_occupied_box" v-if="query.districtType == 1">
                                <p class="board_occupied_title">快充电枪数</p>
                                <p class="board_occupied_num">{{ occupiedData.chargeSubDeviceEx || 0 }}</p>
                            </div>
                            <div class="board_occupied_box" v-if="query.districtType == 1">
                                <p class="board_occupied_title">慢充电枪数</p>
                                <p class="board_occupied_num">{{ occupiedData.chargeSubDeviceDi || 0 }}</p>
                            </div>
                        </div>
                        <div class="chart-content-occupied">
                            <BaseChart ref="occupiedChart" :option="occupiedChartOption"
                                style="width: 100%; height: 100%" />
                            <img src="@/assets/images/newhome/charge.png" alt="">
                        </div>
                    </div>

                </div>
            </div>
            <!-- 三.停车时长、进出场流量、本月车场收益排名 -->
            <div class="board_middle fx bottom12">
                <!-- 1.停车时长 -->
                <div class="board_time board_box">
                    <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
                        <div>
                            <span style="margin-left: 0;">充电次数趋势</span>
                        </div>
                        <div style="display: flex;" v-if="query.districtType == 1">
                            <div class="margin-left20">
                                <el-radio-group v-model="query.type5" size="small" @change="getChargeNumRevenueSort">
                                    <el-radio-button label="1">快充</el-radio-button>
                                    <el-radio-button label="0">慢充</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="chart-content-time">
                        <!-- <BaseChart ref="timeChart" :option="timeChartOption" style="width: 100%; height: 100%" /> -->
                        <BaseChart ref="chargeNumChart" :option="chargeNumOption" style="width: 100%; height: 100%" />
                    </div>
                </div>
                <!-- 2.进出场流量 -->
                <div class="board_flow board_box">
                    <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
                        <div>

                            <span style="margin-left: 0;">充电量趋势</span>
                        </div>
                        <div style="display: flex;" v-if="query.districtType == 1">
                            <div class="margin-left20">
                                <el-radio-group v-model="query.type6" size="small" @change="getFlowRevenueSort">
                                    <el-radio-button label="1">快充</el-radio-button>
                                    <el-radio-button label="0">慢充</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="chart-content-flow">
                        <BaseChart ref="flowChart" :option="flowChartOption" style="width: 100%; height: 100%" />
                    </div>

                </div>
                <!-- 3.本月车场 -->
                <div class="board_space board_box">
                    <div class="headTitle">
                        充电站收益top5
                    </div>
                    <div class="chart-content_space ">
                        <el-table :data="chargeIncomeSortDataList" border class="common-table"
                            header-cell-style="background-color: rgba(0, 0, 0, 0.02);color: rgba(0, 0, 0, 0.88);font-weight:600;"
                            size="medium" style="width: 100%">
                            <el-table-column align="left" fixed="left" label="电站名称" prop="ccmc" min-width="80%">
                                <template slot-scope="scope">
                                    <div style="display: flex;">
                                        <img v-if="scope.$index < 3" src="@/assets/images/newhome/top_1.png"
                                            class="width20 height20" />
                                        <img v-else src="@/assets/images/newhome/top_2.png" class="width20 height20" />
                                        <div class="font-size14 margin-left8">
                                            {{ scope.row.name }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="left" fixed="left" label="收入（元）" prop="amount" min-width="20%">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseChart from "@/components/BaseChart";
// import * as echarts from "echarts";
import {
    homeDataStatistics, // 总览数据
    chargeDeviceUseRate, // 利用率
    incomeAnalyze, // 收入趋势
    chargeDeviceAnalyze, // 综合分析
    chargeCountAnalyze, // 充电次数趋势
    chargeElecAnalyze, // 充电量分析
    chargeIncomeSort, // 收入排名

} from "@/api/charge";
import {
    checkToken,
} from "@/api/common";
import {
    getCommonChargeList
} from "@/api/common";
import { MessageBox } from "element-ui";
import { getRegionList } from "@/api/user";
import { dateFormat } from "@/utils/utils";

export default {
    name: "home",
    components: {
        BaseChart,
    },
    data() {
        return {
            parkinglotTimeSelect: "0", //车场概况时间选择
            query: {
                startDate: "",
                endDate: "",
                timeValue: null,
                regionId: "",
                regionIds: [],
                districtIds: [],
                dateType: "0",
                districtType: "0",
                timeType: "0",
                type2: "1",
                type3: "0",
                type5: "1",
                type6: "1",
            },
            props: {
                checkStrictly: true,
                lazy: true,
                async lazyLoad(node, resolve) {
                    const { level, value } = node;
                    console.log('node.value:', value)
                    let regionId = level === 0 ? 0 : value;
                    getRegionList({ regionId: regionId })
                        .then(({ returnObject }) => {
                            console.log('getRegionList:', returnObject)
                            const nodes = returnObject.regionResultVoList.map(item => ({
                                value: item.id,
                                label: item.name,
                                leaf: item.level == 3,
                            })
                            )
                            resolve(nodes)
                        })
                }
            },
            pickerMinDate: "",
            pickerMonth: null,
            parkingList: [],
            allTotal: {},
            chargeIncomeSortSelect: 0,
            chargeIncomeSortDataList: [], // 收入排名
            screenHeight: document.body.clientHeight - 110,
            screenWidth: document.body.clientWidth,
            // 车位利用率
            zrcwlylChartOption: {
                color: "#0768FD",
                grid: {  //方法 1
                    left: 10,
                    right: 5,
                    bottom: 0,
                    top: 30,
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    boundaryGap: false,
                    axisLabel: {
                        interval: 3,
                        color: '#6C757D'
                    },
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                    },
                    splitLine: {
                        length: 4,
                        lineStyle: {
                            type: "solid",
                            color: 'rgba(234, 234, 234, 1)',
                        },
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                        color: '#6C757D'
                    },
                    axisLabel: {
                        show: true,
                        interval: 0, // 使x轴文字显示全
                        color: '#666666',
                        formatter: '{value}%'  //y轴数值，带百分号
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        borderWidth: "4",
                        lineStyle: {
                            width: 2,
                        },
                        areaStyle: {
                            color: 'rgba(199, 228, 255, 0.7)'
                        },
                    }
                ]
            },
            flowTimeSelect: 0,
            flowTypeSelect: '1',

            vehicleTimeSelect: 0,
            vehicleTypeSelect: '1',
            topChartAverageRevenue: 0,
            // 收入趋势
            topChartOption: {
                height: 300,
                grid: {
                    // width: 1200, //设置宽度为800px
                    containLabel: true,
                    left: 20,
                    right: 20,
                    bottom: 20,
                    top: 30,
                },
                color: ['#0768FD', '#FDB600'],
                legend: {
                    icon: 'circle',
                    data: ['路外车场', '路内车场'],
                    orient: 'horizontal',
                    // bottom: 40,
                    left: 'right',
                    // y:'bottom',
                    top: '0%',
                    show: false,
                    // y:10,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (params) {
                        // var result = ''
                        var l_title = `<div class="font-size12" style="color:rgba(108, 117, 125, 1);">${params[0].axisValue}</div>`
                        var hr = `<div class="margin-bottom8" style="height: 1px;background: rgba(242, 244, 248, 1);"></div>`
                        var dotHtml = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#0768FD"></span>'    // 定义第一个数据前的圆点颜色
                        // var dotHtml2 = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#0768FD"></span>'    // 定义第二个数据前的圆点颜色
                        // result += l_title + "</br>" + dotHtml + ` ${params[0].seriesName} ` + params[0].data + "</br>" + dotHtml2 + ` ${params[1].seriesName} ` + params[1].data;
                        var result2 = `${l_title}${hr}${dotHtml} <b>${params[0].seriesName}\t\t\t\t${params[0].data}</b></br></b>`
                        return result2
                        // return params[0].seriesName+' '+params[0].value
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    // axisLine:{
                    //   symbol: 'arrow',
                    //   lineStyle: {
                    //     type: 'dashed'
                    //   }
                    // },
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                    },
                    data: [],
                    // axisTick: {
                    //   show: true
                    // },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: 'rgba(234, 234, 234, 1)',
                        },
                        show: false,
                    },
                },
                yAxis: {
                    type: "value",
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                    },
                },
                series: [
                    {
                      data: [],
                      type: "line",
                      name: '收入',
                      // smooth: true,
                      // type: "bar",
                      // barWidth: "20%",
                      itemStyle: {
                        color: "#0768FD",
                      },
                      lineStyle: {
                        width: 4,
                      },
                    },
                    {
                        data: [],
                        name: '慢充',
                        type: "line",
                        // smooth: true,
                        // type: "bar",
                        // barWidth: "20%",
                        itemStyle: {
                            color: "#0768FD",
                        },
                        lineStyle: {
                            width: 2,
                        },
                        symbolSize: 5
                    },
                ],
            },
            occupiedData: {},
            occupiedDataMore: {},
            // 泊位占用分析
            occupiedChartOption: {
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b}: {c} ({d}%)"
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    x: 'right',
                    data: ['90%', '10%'],  //显示的百分比
                    textStyle: {
                        color: "#fff",
                        fontSize: "12"
                    }

                },
                graphic: {
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    style: {
                        text: '',     //环形中间文字及样式
                        textAlign: 'center',
                        fill: '#000000',
                        width: 30,
                        height: 30,
                        fontSize: "12"
                    }
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '60%'],//第一个参数是内圆半径，第二个参数是外圆半径
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    textStyle: { color: 'white', fontSize: "0" },
                                    //让series 中的文字进行换行
                                    // formatter:function(val){
                                    //     return val.name.split("-").join("\n");}
                                },
                                title: {
                                    text: 'aaaa'
                                },
                                labelLine: {
                                    show: true,
                                    lineStyle: { color: 'white' }
                                }
                            },

                            // emphasis: {
                            //   // show:false,
                            //   shadowBlur: 10,
                            //   shadowOffsetX: 0,
                            //   shadowColor: 'rgba(0, 0, 0, 0.5)',
                            //   textColor: 'red'
                            // }
                        },
                        emphasis: {
                            disabled: true
                        },
                        data: [
                            { value: 0, name: '占用' },
                            { value: 0, name: '空闲' },
                        ]
                    }
                ],
                color: ['#EAF2F7', '#08BF9E']
            },
            // 充电次数趋势
            timeChartOption: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    // left: '3%',
                    // right: '4%',
                    // bottom: '3%',
                    containLabel: true,
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 20,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['30分钟以下', '30-60分钟', '1-3小时', '3小时以上', '1天以上'],


                        axisLabel: {
                            interval: 0,
                            color: '#6C757D'
                        },
                        axisTick: {
                            length: 6,
                            lineStyle: {
                                type: 'dashed',
                                color: 'rgba(205, 205, 205, 1)',
                            },
                        },
                        splitLine: {
                            length: 4,
                            lineStyle: {
                                type: "solid",
                                color: 'rgba(234, 234, 234, 1)',
                            },
                            show: false,
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        barWidth: '30%',
                        data: [10, 52, 200, 330, 220],
                        color: "#0768FD"
                    }
                ]
            },
            // 充电次数分析
            chargeNumOption: {
                // color: "#641DFF",
                color: ['#641DFF', '#56C2FF'],
                grid: {  //方法 1
                    left: 10,
                    right: 5,
                    bottom: 0,
                    top: 20,
                    containLabel: true
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (params) {
                        // var result = ''
                        var l_title = `<div class="font-size12" style="color:rgba(108, 117, 125, 1);">${params[0].axisValue}</div>`
                        var hr = `<div class="margin-bottom8" style="height: 1px;background: rgba(242, 244, 248, 1);"></div>`
                        var dotHtml = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#641DFF"></span>'    // 定义第一个数据前的圆点颜色
                        var dotHtml2 = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#56C2FF"></span>'    // 定义第二个数据前的圆点颜色
                        // result += l_title + "</br>" + dotHtml + ` ${params[0].seriesName} ` + params[0].data + "</br>" + dotHtml2 + ` ${params[1].seriesName} ` + params[1].data;
                        var result2 = `${l_title}${hr}${dotHtml} <b>${params[0].seriesName}\t\t\t\t${params[0].data}</b></br>${dotHtml2} <b>${params[1].seriesName}\t\t${params[1].data}</b>`
                        return result2
                        // return params[0].seriesName+' '+params[0].value
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    boundaryGap: false,
                    axisLabel: {
                        interval: 3,
                        color: '#6C757D'
                    },
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                    },
                    splitLine: {
                        length: 4,
                        lineStyle: {
                            type: "solid",
                            color: '#56C2FF',
                        },
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                        color: '#6C757D'
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        name: "充电次数",
                        smooth: true,
                        symbol: 'none',
                        borderWidth: "4",
                        lineStyle: {
                            width: 2,
                        },
                        symbolSize: 5,
                        areaStyle: {
                            // color: 'rgba(199, 228, 255, 0.7)'
                        },
                    },
                    {
                        data: [],
                        type: 'line',
                        name: "",
                        smooth: true,
                        symbol: 'none',
                        borderWidth: "4",
                        lineStyle: {
                            width: 2,
                        },
                        symbolSize: 5,
                        areaStyle: {
                            color: '#56C2FF'
                        },
                    }
                ]
            },
            // 充电量趋势分析
            flowChartOption: {
                // color: "#641DFF",
                color: ['#641DFF', '#56C2FF'],
                grid: {  //方法 1
                    left: 10,
                    right: 5,
                    bottom: 0,
                    top: 20,
                    containLabel: true
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (params) {
                        // var result = ''
                        var l_title = `<div class="font-size12" style="color:rgba(108, 117, 125, 1);">${params[0].axisValue}</div>`
                        var hr = `<div class="margin-bottom8" style="height: 1px;background: rgba(242, 244, 248, 1);"></div>`
                        var dotHtml = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#641DFF"></span>'    // 定义第一个数据前的圆点颜色
                        var dotHtml2 = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#56C2FF"></span>'    // 定义第二个数据前的圆点颜色
                        // result += l_title + "</br>" + dotHtml + ` ${params[0].seriesName} ` + params[0].data + "</br>" + dotHtml2 + ` ${params[1].seriesName} ` + params[1].data;
                        var result2 = `${l_title}${hr}${dotHtml} <b>${params[0].seriesName}\t\t\t\t${params[0].data}</b></br>${dotHtml2} <b>${params[1].seriesName}\t\t${params[1].data}</b>`
                        return result2
                        // return params[0].seriesName+' '+params[0].value
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    boundaryGap: false,
                    axisLabel: {
                        interval: 3,
                        color: '#6C757D'
                    },
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                    },
                    splitLine: {
                        length: 4,
                        lineStyle: {
                            type: "solid",
                            color: '#56C2FF',
                        },
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(205, 205, 205, 1)',
                        },
                        color: '#6C757D'
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        name: "充电量",
                        smooth: true,
                        symbol: 'none',
                        borderWidth: "4",
                        lineStyle: {
                            width: 2,
                        },
                        symbolSize: 5,
                        areaStyle: {
                            // color: 'rgba(199, 228, 255, 0.7)'
                        },
                    },
                    {
                        data: [],
                        type: 'line',
                        name: "",
                        smooth: true,
                        symbol: 'none',
                        borderWidth: "4",
                        lineStyle: {
                            width: 2,
                        },
                        symbolSize: 5,
                        areaStyle: {
                            color: '#56C2FF'
                        },
                    }
                ]
            },
            chart: null,
        }
    },
    mounted() {
        // this.isLoading = true;
        const that = this
        that.setChartSize()

        window.onresize = () => {
            return (() => {
                // 可以限制最小高度
                // if (document.body.clientHeight - 240 < 450) {
                //   return
                // }

                window.screenHeight = document.body.clientHeight - 110
                window.screenWidth = document.body.clientWidth
                that.screenHeight = window.screenHeight < 900 ? 900 : window.screenHeight
                that.screenWidth = window.screenWidth

                that.setChartSize()

            })()
        }
        checkToken()
            .then(res => {
                if (!res || res.code !== 30) {
                    MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                        confirmButtonText: '重新登录',
                        callback: () => {
                            window.localStorage.clear()
                            window.$vue.$router.push('/login')
                        }
                    });
                } else {
                    this.getNowDate();
                    getCommonChargeList().then(res => {
                        if (res && res.code === 30 && res.result) {
                            this.parkingList = res.returnObject;
                        }
                    })
                    // 地区ids
                    if (localStorage.getItem("regionIds")) {
                        this.query.regionIds = JSON.parse(localStorage.getItem("regionIds"))
                        this.query.regionId = this.query.regionIds[this.query.regionIds.length - 1]
                    }
                    Promise.all([
                        this.homeDataStatistics(),
                        this.getchargeIncomeSort(0),
                        this.incomeAnalyze(),
                        this.getChargeDeviceAnalyze(),
                        this.chargeDeviceUseRate(),
                        this.getChargeNumRevenueSort(),
                        this.getFlowRevenueSort(),
                    ]).then(() => {
                        // this.isLoading = false;
                    });
                }
            })
    },
    methods: {
        setChartSize() {

            this.zrcwlylChartOption.height = 170 / 1920 * this.screenWidth;
            this.$refs.zrcwlylChart.render(this.zrcwlylChartOption);
            this.$refs.zrcwlylChart.onResize();

            this.topChartOption.height = 175 / 1920 * this.screenWidth;
            this.$refs.topChart.render(this.topChartOption);
            this.$refs.topChart.onResize();

            this.occupiedChartOption.height = 260 / 1920 * this.screenWidth;
            this.$refs.occupiedChart.render(this.occupiedChartOption);
            this.$refs.occupiedChart.onResize();


            this.chargeNumOption.height = 240 / 1920 * this.screenWidth;
            this.$refs.chargeNumChart.render(this.chargeNumOption);
            this.$refs.chargeNumChart.onResize();

            this.flowChartOption.height = 240 / 1920 * this.screenWidth;
            this.$refs.flowChart.render(this.flowChartOption);
            this.$refs.flowChart.onResize();
        },
        getNowDate() {
            this.query.startDate = dateFormat("yyyy-mm-dd", new Date());
            this.query.endDate = dateFormat("yyyy-mm-dd", new Date());
            this.query.timeValue = [this.query.startDate, this.query.endDate];
        },
        allPromise() {
            Promise.all([
                this.homeDataStatistics(),
                this.getchargeIncomeSort(0),
                this.incomeAnalyze(),
                this.getChargeDeviceAnalyze(),
                // this.searchParkingLotAnalyze(),
                // this.chargeElecAnalyze(),
                this.chargeDeviceUseRate(),
                // this.deviceAnalysis(),
                this.getChargeNumRevenueSort(),
                this.getFlowRevenueSort(),
            ]).then(() => {
                // this.isLoading = false;
            });
        },
        changeDate(e) {
            this.query.timeValue = e;
            if (e) {
                this.query.startDate = e[0];
                this.query.endDate = e[1];
            } else {
                this.query.startDate = "";
                this.query.endDate = "";
            }
            this.allPromise();
        },
        onChangeParkingLot() {
            this.allPromise();
        },
        changeRegion(e) {
            if (e) {
                this.query.regionId = e[e.length - 1]
            } else {
                this.query.regionId = "";
            }
            this.allPromise();
        },
        // 数据总览
        async homeDataStatistics() {
            const { returnObject } = await homeDataStatistics(this.query);
            this.allTotal = returnObject;
        },
        // 利用率
        async chargeDeviceUseRate() {
            chargeDeviceUseRate({ deviceType: this.query.type2, ...this.query })
                .then(res => {
                    if (res && res.code === 30 && res.result) {
                        this.zrcwlylChartOption.xAxis.data = []
                        this.zrcwlylChartOption.series[0].data = []
                        for (var key in res.returnObject.chargeUseRateAnalyze[0]) {
                            this.zrcwlylChartOption.xAxis.data.push(key)
                            this.zrcwlylChartOption.series[0].data.push(res.returnObject.chargeUseRateAnalyze[0][key] * 100)
                        }
                        this.$refs.zrcwlylChart.render(this.zrcwlylChartOption);
                        this.setChartSize()
                    }
                })
        },
        // 收入趋势
        async incomeAnalyze() {
            const res = await incomeAnalyze({ deviceType: this.query.type3, ...this.query });
            if (res && res.code === 30 && res.result) {
                this.topChartOption.xAxis.data = []
                this.topChartOption.series[0].data = []
                this.topChartOption.series[1].data = []
                if (this.query.districtType == 0) {
                    for (var key in res.returnObject.chargeDistrict[0]) {
                        this.topChartOption.xAxis.data.push(key)
                        // this.topChartOption.series[0].data.push(0)
                        this.topChartOption.series[0].data.push(res.returnObject.chargeDistrict[0][key])
                    }
                    this.$refs.topChart.render(this.topChartOption);
                } else {
                    for (var key1 in res.returnObject.chargeDistrictDi[0]) {
                        this.topChartOption.xAxis.data.push(key1)
                        this.topChartOption.series[0].data.push(res.returnObject.chargeDistrictDi[0][key1])
                        this.topChartOption.series[1].data.push(res.returnObject.chargeDistrictEx[0][key1])
                    }
                    this.$refs.topChart.render(this.topChartOption);
                }

            }
        },
        // 综合分析
        async getChargeDeviceAnalyze() {

            const res = await chargeDeviceAnalyze({ ...this.query });
            if (res && res.code === 30 && res.result) {
                this.occupiedData = res.returnObject;
                this.occupiedChartOption.series[0].data[0].value = res.returnObject.chargeSubDeviceFree // 泊位空闲
                this.occupiedChartOption.series[0].data[1].value = res.returnObject.chargeSubDeviceUse // 泊位占用
                this.$refs.occupiedChart.render(this.occupiedChartOption)
            }


        },

        // 获取车场收入TOP10数据
        async getchargeIncomeSort() {
            const res = await chargeIncomeSort({ ...this.query });
            if (res && res.code === 30 && res.result) {
                this.chargeIncomeSortDataList = res.returnObject.chargeIncomeSort.slice(0, 5);
            }
        },

        // 充电次数分析
        async getChargeNumRevenueSort() {
            let deviceType = this.query.type5
            console.log(this.query.type5,deviceType);
            
            const res = await chargeCountAnalyze({  ...this.query,deviceType: this.query.type5 });
            if (res && res.code === 30 && res.result) {
                this.chargeNumOption.xAxis.data = []
                this.chargeNumOption.series[0].data = []
                // this.chargeNumOption.series[1].data = []
                for (var key in res.returnObject.chargeCountAnalyze[0]) {
                    this.chargeNumOption.xAxis.data.push(key)
                    // this.chargeNumOption.series[1].data.push(Number(res.returnObject.chargeCountAnalyze[0][key]))
                    this.chargeNumOption.series[0].data.push(Number(res.returnObject.chargeCountAnalyze[0][key]))
                }
                this.$refs.chargeNumChart.render(this.chargeNumOption);

            }

        },
        // 充电量分析
        async getFlowRevenueSort() {
            const res = await chargeElecAnalyze({ deviceType: this.query.type6, orderType: this.flowTypeSelect, ...this.query });
            if (res && res.code === 30 && res.result) {
                this.flowChartOption.xAxis.data = []
                this.flowChartOption.series[0].data = []
                this.flowChartOption.series[1].data = []
                for (var key in res.returnObject.chargeElecAnalyze[0]) {
                    this.flowChartOption.xAxis.data.push(key)
                    // this.flowChartOption.series[1].data.push(Number(res.returnObject.chargeElecAnalyze[0][key]))
                    this.flowChartOption.series[0].data.push(Number(res.returnObject.chargeElecAnalyze[0][key]))
                }
                this.$refs.flowChart.render(this.flowChartOption);

            }

        },
    }
}
</script>

<style lang="less" scoped>
.home {
    min-width: 1600px;

    .right12 {
        margin-right: 12px;
    }

    .bottom12 {
        margin-bottom: 12px;
    }

    .fx {
        display: flex;
        align-items: center;
    }

    .flx {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .headTitle {
        font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
        font-weight: 600;
        font-size: 18px;
        color: #343A40;
        line-height: 20px;
        // margin-bottom: 20px;
        margin: 15px 25px 0px;
    }

    .board_box {
        background-color: white;
        border-radius: 12px;
    }

    .board {
        width: 100%;
        height: 100%;

        .board_top {
            height: 252px;

            // .top_data_block:nth-child(1) {  
            //   background-color: #ffcccc; /* 第一个块的背景色 */  
            // } 
            .top_data_block1 {

                background: linear-gradient(135deg, #FFFFFF 0%, #D7F2F9 100%);
            }

            .top_data_block2 {
                background: linear-gradient(134deg, #D6F1E1 0%, #C7EFE4 100%);
            }

            .top_data_block3 {
                background: linear-gradient(134deg, #C2E8FF 0%, #7EBFFF 100%);
            }

            .top_data_block4 {
                background: linear-gradient(135deg, #FCF6D7 0%, #F8EABC 100%);
            }

            .top_data_block5 {
                background: linear-gradient(136deg, #B3CDFF 0%, #7F94FF 100%);
            }

            .top_data_block6 {
                // background: linear-gradient(134deg, #C9C9C9 0%, #767B7E 100%);
                background: linear-gradient(134deg, #E3E7EE 0%, #D4D9E1 100%);
            }

            .top_data {
                height: 100%;
                // align-items: flex-end;
                flex: 1;

                .top_data_block {
                    border-radius: 12px;
                    width: 33%;
                    height: 120px;
                }

                .top_img {
                    width: 56px;
                    height: 56px;
                    margin-left: 30px;
                    margin-right: 42px;
                }

                .top_main {}

                .top_main_title {
                    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
                    font-weight: 400;
                    font-size: 16px;
                    color: #334A65;
                    line-height: 19px;
                    margin-bottom: 10px;
                }

                .top_main_num {
                    font-family: FZLanTingHei-EB-GBK, FZLanTingHei-EB-GBK;
                    font-weight: 400;
                    font-size: 22px;
                    color: #000000;
                    line-height: 26px;
                    letter-spacing: 1px;
                }

                .top_main_bottom {
                    margin-top: 10px;

                    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
                    font-weight: 400;
                    font-size: 16px;
                    color: #334A65;
                    line-height: 19px;
                }
            }

            .top_use {
                width: calc(34% - 12px);
                height: 100%;

                .chart-content {
                    // 图表
                    height: 228px;
                }
            }
        }

        .board_second {
            height: 300px;

            .board_income {
                height: 100%;
                margin-right: 12px;

                .chart-content-income {
                    height: 260px;
                }
            }

            .board_occupied {
                width: calc(34% - 12px);
                height: 100%;

                .board_occupied_btm {}

                .board_occupied_main {
                    width: 50%;
                    padding: 15px 32px;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                .board_occupied_box {
                    width: 40%;
                    text-align: center;
                    padding: 15px 0;
                }

                .board_occupied_title {
                    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
                    font-weight: 400;
                    font-size: 14px;
                    color: #334A65;
                    line-height: 16px;
                    margin-bottom: 5px;
                }

                .board_occupied_num {
                    font-family: FZLanTingHei-EB-GBK, FZLanTingHei-EB-GBK;
                    font-weight: 600;
                    font-size: 16px;
                    color: #000000;
                    line-height: 19px;
                    letter-spacing: 1px;
                }

                .chart-content-occupied {
                    width: 50%;
                    height: 260px;
                    position: relative;

                    img {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        top: 43%;
                        left: 43%;
                    }
                }
            }
        }

        .board_middle {
            height: 351px;

            .board_time {
                width: calc(33% - 6px);
                height: 100%;
                margin-right: 12px;

                .chart-content-time {
                    height: 300px;
                }
            }

            .board_flow {
                height: 100%;
                width: calc(33% - 6px);
                margin-right: 12px;

                .chart-content-flow {
                    height: 320px;
                }
            }

            .board_space {
                width: calc(34% - 12px);
                height: 100%;

                .chart-content_space {
                    padding: 15px;
                }
            }
        }

        .board_bottom {
            height: 378px;

            .board_type {
                width: calc(33% - 6px);
                height: 100%;
                margin-right: 12px;

                .chart-content-type {
                    height: 350px;
                }
            }

            .board_task {
                width: calc(33% - 6px);
                height: 100%;
                margin-right: 12px;

                .chart-content-task {
                    height: 350px;
                }

                .board_task_select {
                    margin-top: 10px;
                    margin-right: 20px;
                }
            }

            .board_analysis {
                width: 33%;
                height: 100%;

                .board_analysis_main {}

                .board_analysis_box {
                    padding: 20px 20px;
                }

                .board_analysis_block {
                    width: 226px;
                    height: 76px;
                    background: #F0F3F7;
                    border-radius: 4px;

                    img {
                        margin-left: 30px;
                        width: 29px;
                        height: 29px;
                        margin-right: 20px;
                    }
                }

                .board_analysis_title {
                    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
                    font-weight: 400;
                    font-size: 14px;
                    color: #5F666C;
                    line-height: 14px;
                    margin-bottom: 5px;
                }

                .board_analysis_num {
                    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
                    font-weight: 400;
                    font-size: 22px;
                    color: #000000;
                    line-height: 22px;
                }

                .chart-content-analysis {
                    height: 260px;
                }
            }
        }
    }
}



/deep/ .el-radio-button__orig-radio+.el-radio-button__inner:hover {
    color: #0768FD !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #0768FD !important;
    border-color: #0768FD !important;
    box-shadow: -1px 0 0 0 #0768FD !important;
}

/deep/ .el-radio-button__inner {
    background-color: #F3F3F3;
    border: none !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover,
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner:focus {
    color: #ffffff !important;
}

/deep/ .el-tooltip__popper.is-light {
    border: none !important;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

/deep/ .el-date-editor .el-range-input {
    background-color: white !important;
}

///deep/ .el-tooltip{
//  border: white 1px solid;
//}
// 修改三角的背景颜色 el-tooltip__popper is-light tooltip
///deep/ .el-tooltip__popper .popper__arrow::after {
//  border-top-color: #0A3C84;
//}
//// 修改三角的边框颜色
///deep/ .el-tooltip__popper .popper__arrow {
//  border-top-color: #4183C9;
//}</style>
<style lang="css" scoped>
/deep/ .el-tooltip__popper.is-light {
    border: none !important;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.demo-form-inline {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

/deep/ .el-input__suffix .el-icon-arrow-down {
    color: #909399 !important;
}

.parking-item {
    margin-right: 0px !important;
    flex: 1;

    .el-form-item__label {
        width: 60px;
    }

    .el-form-item__content {
        width: calc(100% - 60px);
    }
}

.el-input {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-wrap: nowrap;
}

/deep/ .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
    display: flex !important;
    align-items: center;
    background-color: #fff !important;

    i {
        height: 30px !important;
    }

    input {
        line-height: 30 !important;
    }

    .el-range-separator {

        line-height: 1.8;

    }
}

/deep/ .el-date-editor {
    margin-top: 2px;
}
</style>