import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';


// 电站列表
export function chargePage(params){
    return request.post('/charge/chargePage?' + objectToQueryString(params));
}

// 电站详情
export function chargeInfo(params){
    return request.post('/charge/chargeInfo?' + objectToQueryString(params));
}

// 创建电站
export function createCharge(params){
    return request.post('/charge/createCharge?' + objectToQueryString(params));
}

// 编辑电站
export function editCharge(params){
    return request.post('/charge/editCharge?' + objectToQueryString(params));
}

// 充电规则列表
export function chargeRulePage(params){
    return request.post('/charge/chargeRulePage?' + objectToQueryString(params));
}

// 充电规则详情
export function chargeRuleInfo(params){
    return request.post('/charge/chargeRuleInfo?' + objectToQueryString(params));
}
// 创建计费规则
export function createChargeRule(params){
    return request.post('/charge/createChargeRule',params);
}

// 编辑计费规则
export function editChargeRule(params){
    return request.post('/charge/editChargeRule',params);
}

// 充电订单分页查询
export function chargeOrderPage(params){
    return request.post('/charge/chargeOrderPage?' + objectToQueryString(params));
}

// 删除电站
export function delCharge(params){
    return request.post('/charge/delCharge?' + objectToQueryString(params));
}








// 充电看板

// 顶部数据
export function homeDataStatistics(params){
    return request.post('/base/charge/homeDataStatistics?' + objectToQueryString(params));
}

// 利用率
export function chargeDeviceUseRate(params){
    return request.post('/base/charge/chargeDeviceUseRate?' + objectToQueryString(params));
}

// 收入趋势
export function incomeAnalyze(params){
    return request.post('/base/charge/incomeAnalyze?' + objectToQueryString(params));
}

// 综合分析
export function chargeDeviceAnalyze(params){
    return request.post('/base/charge/chargeDeviceAnalyze?' + objectToQueryString(params));
}

// 次数趋势
export function chargeCountAnalyze(params){
    return request.post('/base/charge/chargeCountAnalyze?' + objectToQueryString(params));
}

// 电量趋势
export function chargeElecAnalyze(params){
    return request.post('/base/charge/chargeElecAnalyze?' + objectToQueryString(params));
}

// 排名
export function chargeIncomeSort(params){
    return request.post('/base/charge/chargeIncomeSort?' + objectToQueryString(params));
}